// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global
 define
 */

/**
 * This module provides access to service APIs that helps to display context menu.
 *
 * @module js/contextMenuService
 * @requires app
 */
define( 'js/contextMenuService',[ 'app', 'angular', 'jquery', 'js/command.service',  //
'js/appCtxService' ], function( app, ngModule, $ ) {
    'use strict';

    /**
     * Provides access to the compareService
     *
     * @class compareService
     * @memberOf NgServices
     */
    app.service( 'contextMenuService', [
        function( ) {

            var self = this;

            /**
             * utility function to get the scope reference (if set) on the controller under the parent element.
             *
             * @param {Event} closestTo - the event object generated by hammer
             * @param {Event} event - the event object generated by hammer
             * @param {Object} scope - scope of the element clicked
             */
            self.showContextMenu = function( closestTo, event  ) {

                if($(event.target).closest(closestTo).length){
                    var currParentElWithPopup = null;

                    /*  are we in table */
                    currParentElWithPopup = ngModule.element( $(event.target).closest('aw-table') );
                    if (currParentElWithPopup.length === 0) {
                        /* are we in list */
                        currParentElWithPopup = ngModule.element( $(event.target).closest('div.aw-widgets-modelObjectList') );
                    }

                    if (currParentElWithPopup.length > 0) {
                        /* get the popup element from the table or list */
                        var popupUpEl = currParentElWithPopup.find('aw-popup-panel');
                        currParentElWithPopup.scope().$broadcast( "awPopupWidget.open", {
                            popupUpLevelElement: popupUpEl
                            },
                            currParentElWithPopup,
                            event,
                            "contextMenu"
                        );
                    }

                }

            };
    } ] );
} );

