// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global
 define
 */

/**
 * Defines {@link NgServices.commandPanelService} which manages command panels.
 *
 * @module js/commandPanel.service
 */
define( 'js/commandPanel.service',[ 'app', 'js/logger', 'js/appCtxService' ], function( app, logger ) {
    'use strict';

    /**
     * Command service to manage commands.
     *
     * @param appCtxService {Object} - App context service
     */
    app.service( 'commandPanelService', [ '$q', 'appCtxService', function( $q, appCtxService ) {

        /**
         * Activate a command. Closes any panel that is open in the opposite panel location. If the command jas the
         * same is as the command active at that location it will be closed. If any setup is required for the
         * command just wrap this service.
         *
         * @param {String} commandId - ID of the command to open. Should map to the view model to activate.
         * @param {String} location - Which panel to open the command in. "aw_navigation" or "aw_toolsAndInfo"
         * @param {Object} context - The panel context.
         * @param {Boolean} push - Optional parameter to push workarea content when opening command panel
         * @param {Boolean} closeWhenCommandHidden - Optional parameter to disable the automatic closing of the panel when a command is hidden. Defaults to true.
         */
        this.activateCommandPanel = function( commandId, location, context, push, closeWhenCommandHidden ) {

            //Create a "fake" command overlay that only contains the information necessary to display a panel
            var overlay = {
                commandId: commandId,
                closeWhenCommandHidden: closeWhenCommandHidden !== false,
                getDeclarativeCommandId: function() {
                    return commandId;
                },
                //Register panel context on activation of command
                setupDeclarativeView: function( deferred ) {
                    if( context ) {
                        appCtxService.registerCtx( 'panelContext', context );
                    }
                    deferred.resolve();
                },
                //Unregister panel context on close of command
                callbackApi: {
                    getPanelLifeCycleClose: function( _, deferred ) {
                        if( context ) {
                            appCtxService.unRegisterCtx( 'panelContext' );
                        }
                        deferred.resolve();
                    },
                    setActive: function() {
                        //
                    }
                },
                push: push
            };

            //Only nav and tools and info can open panels currently
            //Support for any command area would require having a single "active command" context instead of one for each area
            if( [ 'aw_navigation', 'aw_toolsAndInfo' ].indexOf( location ) === -1 ) {
                logger.error( 'Unknown command panel location', location );
            } else {
                var locationCtx = location === 'aw_navigation' ? 'activeNavigationCommand' :
                    'activeToolsAndInfoCommand';
                var otherLocationCtx = location === 'aw_navigation' ? 'activeToolsAndInfoCommand' :
                    'activeNavigationCommand';

                //aw-command-area does the same check, but it is better to do it here.
                var activeCommand = appCtxService.getCtx( locationCtx );
                //If the currently opened command has the same id as the new command
                if( activeCommand && activeCommand.commandId === commandId ) {
                    //Close the panel
                    appCtxService.unRegisterCtx( locationCtx );
                } else {
                    //If a panel in the other area is open close it
                    var otherPanelCommand = appCtxService.getCtx( otherLocationCtx );
                    if( otherPanelCommand ) {
                        appCtxService.unRegisterCtx( otherLocationCtx );
                    }
                    //Open the new panel
                    appCtxService.registerCtx( locationCtx, overlay );
                }
            }
        };
    } ] );

    return {
        moduleServiceNameToInject: 'commandPanelService'
    };
} );

