// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global
 define,
 window
 */

/**
 * Definition for the (aw-property-non-edit-val) directive.
 *
 * @module js/aw-property-non-edit-val.directive
 */
define( 'js/aw-property-non-edit-val.directive',[
    'app',
    //
    'js/aw-parse-html.directive',
    'js/aw-highlight-property-html.directive',
    'js/showObjectCommandHandler'
], function( app ) {
    'use strict';

    /**
     * Definition for the (aw-property-non-edit-val) directive.
     *
     * @example <aw-property-non-edit-val></aw-property-non-edit-val>
     *
     * @member aw-property-non-edit-val
     * @memberof NgElementDirectives
     */
    app.directive( 'awPropertyNonEditVal', //
        [ 'showObjectCommandHandler', '$timeout', '$sce', function( showObjectCommandHndlr, $timeout, $sce ) {
            /**
             * Controller used for prop update or pass in using &?
             *
             * @param {Object} $scope - The allocated scope for this controller
             */
            function myController( $scope ) {

                $scope.openObjectLinkPage = function( $event ) {
                    var uiProperty = $scope.prop;
                    var uid = '';

                    //This is to prevent this event being lost in table if link is in a cell.
                    $event.stopPropagation();

                    // UI might be nicer if we didn't tease the link when editable, but could argue it is a nice reminder that
                    // the text represents an object. Also, more efficient to 1-way bind the ng-show in aw-property-non-edit-val.directive.html.
                    // so handling that here
                    if( uiProperty.isEditable ) {
                        return;
                    }

                    if( uiProperty.isArray ) {
                        uid = uiProperty.dbValue[ $scope.index ];
                    } else {
                        uid = uiProperty.dbValue;
                    }

                    if( uiProperty.whatAmI === 'propDC' ) {
                        /**
                         * FIXME: This is temp code in phase 0 to get r-o object links working... proper solution coming in
                         * p1.
                         */
                        window.location = "#com.siemens.splm.clientfx.tcui.xrt.showObject;uid=" + uid;
                    } else if( uiProperty.propApi && uiProperty.propApi.openObjectLinkPage ) {
                        uiProperty.propApi.openObjectLinkPage( uiProperty.propertyName, uid );
                    } else {
                        showObjectCommandHndlr.execute( {
                            "propertyName": uiProperty.propertyName,
                            "uid": uid
                        } );
                    }
                };
            }

            myController.$inject = [ '$scope' ];

            // add directive controller for prop update or pass in using &?
            return {
                restrict: 'E',
                scope: {
                    // 'prop' is defined in the parent (i.e. controller's) scope
                    prop: '<',
                    index: '@',
                    inTableCell: '@'
                },
                link: function( $scope, $element ) {
                    if( !$scope.prop ) {
                        return;
                    }

                    if( $scope.prop && $scope.prop.isRichText ) {
                        var trustedHtml = "<ul>" + '<li>' + $scope.prop.uiValue + '</li>' + "</ul>";
                        $scope.trustedRichtext = $sce.trustAsHtml( trustedHtml );

                        $scope.showTooltip = false;
                        $scope.toggleTooltip = function( toggle ) {
                            if( toggle ) {
                                $timeout.cancel( $scope.timeoutPromise );
                                $scope.timeoutPromise = $timeout( function() {
                                    $scope.showTooltip = true;
                                }, 750 );
                            } else {
                                $timeout.cancel( $scope.timeoutPromise );
                                $scope.timeoutPromise = $timeout( function() {
                                    $scope.showTooltip = false;
                                }, 500 );
                            }
                        };
                    }

                    $scope.$on( '$destroy', function() {
                        if( $scope.timeoutPromise ) {
                            $timeout.cancel( $scope.timeoutPromise );
                        }
                        $element.remove();
                        $element.empty();
                    } );
                },
                controller: myController,
                templateUrl: app.getBaseUrlPath() + '/html/aw-property-non-edit-val.directive.html'
            };
        } ] );
} );

