// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global
 define
 angular
 */
/**
 * @module js/aw-popup.directive
 */
define( 'js/aw-popup.directive',[ 'app', 'angular', 'js/eventBus', 'lodash', 'js/viewModelService', 'js/popupService', 'js/aw-icon-button.directive',
        'js/exist-when.directive', 'js/aw-command-bar.directive'
    ], //
    function( app, ngModule, eventBus, _ ) {
        'use strict';

        /**
         * Directive to hold content in a popup.
         * @example <aw-popup></aw-popup>
         * @attribute isModal : a consumer can provide an indicator whether it is a modal or modeless, if the attribute is
         *            skipped it would create a modal popup dialog.
         * @member aw-popup class
         * @memberof NgElementDirectives
         */
        app.directive( 'awPopup', [ '$animate', 'viewModelService', function( animate, viewModelSvc ) {
            return {
                restrict: 'E',
                transclude: true,
                replace: false,
                scope: {
                    caption: '@',
                    commands: '=',
                    anchor: '@',
                    isModal: '@?'

                },
                controller: [ '$scope', '$element', 'popupService', function( $scope, $element, popupSvc ) {

                    $scope.showPopup = true;

                    var declViewModel = viewModelSvc.getViewModel( $scope, true );

                    $scope.conditions = declViewModel.getConditionStates();
                    $scope.data = declViewModel;

                    //setting customize height and width
                    if( angular.isDefined( popupSvc.popupInfo ) ) {
                        $scope.height = popupSvc.popupInfo.height;
                        $scope.width = popupSvc.popupInfo.width;
                    }

                    // initialize all default command condition to true
                    _.forEach( $scope.commands, function( command ) {
                        if( command.condition === undefined ) {
                            command.condition = true;
                        }
                    } );

                    $scope.$on( "awPopup.close", function() {
                        $scope.closePopup();
                    } );

                    var closeEventRegistrationDef = eventBus.subscribe( "awPopup.close", function() {
                        $scope.closePopup();
                        $scope.$apply();
                    } );

                    $scope.$on( "$destroy", function() {
                        if( closeEventRegistrationDef ) {
                            eventBus.unsubscribe( closeEventRegistrationDef );
                            closeEventRegistrationDef = null;
                        }
                    } );
                    $scope.closePopup = function() {
                        $scope.showPopup = false;
                        var element = ngModule.element( $element );
                        element.remove();
                        $scope.$destroy();
                        eventBus.publish( "awPopup.destroyed" );
                    };

                    this.setDraggable = function( enableDrag ) { // eslint-disable-line no-invalid-this
                        var element = ngModule.element( $element );

                        var objectTodragg = ngModule.element( element.find( '.aw-popup-contentContainer' ) );

                        if( enableDrag === true ) {
                            objectTodragg.draggable();
                        } else {
                            objectTodragg.draggable( {
                                disabled: true
                            } );
                        }
                    };
                } ],

                link: function( scope, element ) {

                    scope.modal = ( scope.isModal === undefined || scope.isModal === 'true' ) ? true : false;

                    scope.$applyAsync( function() {
                        var container = ngModule.element( element[ 0 ].querySelector( '.aw-popup-contentContainer' ) );
                        animate.addClass( container, 'aw-popup-animate' );

                        //setting customize height and width
                        var popupWindow = container.find('.aw-layout-popup');
                        var popupWindowStyle = container.find('.aw-layout-popup')[0].style;

                        animate.addClass( popupWindow, 'aw-popup-scrollable' );

                        if( scope.height && scope.width ) {
                            popupWindowStyle.height = scope.height + 'px';
                            popupWindowStyle.width = scope.width + 'px';
                        } else {
                            animate.addClass( popupWindow, 'aw-popup-container' );
                        }
                    } );

                    if( _.startsWith( scope.caption, "i18n." ) ) {
                        scope.$watch( 'data.' + scope.caption, function _watchPopupCaption( value ) {
                            scope.caption = value;
                        } );
                    }
                    var eventData = {
                        data: scope.data
                    };
                    eventBus.publish( "awPopup.reveal", eventData );
                },

                templateUrl: app.getBaseUrlPath() + '/html/aw-popup.directive.html'
            };
        } ] );
    } );

