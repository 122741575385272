// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/* global define */

/**
 * native construct to hold the server version information related to the AW server release.
 *
 * @module js/TcAWServerVersion
 * @requires app
 */
define( 'js/TcAWServerVersion',[
    'app', 'js/eventBus', 'js/logger'
], function( app, eventBus, logger ) {
    'use strict';

    var exports = {};

    /**
     * members to hold the parts of the version data.
     */
    exports.baseLine = '';
    exports.buildDate = '';
    exports.buildTime = '';

    var AW_SERVER_VERSION_DELIMITER_ESCAPED = ";";

    /**
     * parse the string into the expected values. Populates members with parsed data.
     *
     * @param {String} versionString - version info from TC
     */
    exports.parseVersionInfo = function( versionString ) {
        if( versionString ) {
            var tokens = versionString.split( AW_SERVER_VERSION_DELIMITER_ESCAPED );
            exports.baseLine = tokens[ 0 ];
            exports.buildDate = tokens[ 1 ];
            exports.buildTime = tokens[ 2 ];
        }
    };

    /**
     * create a formatted string representation of the version parts
     *
     * @return {String} formatted version string
     */
    exports.toString = function() {
        return exports.baseLine + ";" + exports.buildDate + ";" + exports.buildTime;
    };

    /**
     * Definition for the service. tracks version info for the AW build version.
     *
     * @memberof NgServices
     * @member TcAWServerVersion
     *
     * @return {Object} service exports
     */
    app.factory( 'TcAWServerVersion', [ function() {
        eventBus.subscribe( 'afx.logVersionInfo', function() {
            var output = 'Server Build: ' + exports.toString();
            logger.info( output );
        } );
        return exports;
    } ] );

    /**
     * Since this module can be loaded GWT-side by the ModuleLoader class we need to return an object indicating which
     * service should be injected to provide the API for this module.
     */
    return {
        moduleServiceNameToInject: 'TcAWServerVersion'
    };
} );

