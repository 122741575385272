// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/* global define */

/**
 *
 * This service is used to manage the configuration for fetching the View and ViewModel files.
 *
 * Please refer {@link https://gitlab.industrysoftware.automation.siemens.com/Apollo/afx/wikis/solution#solution-configuration-for-obtaining-declarative-view-and-view-model|Solution configuration for obtaining declarative View and View Model}
 *
 * @module js/panelContentService
 *
 * @publishedApolloService
 */
define( 'js/panelContentService',[
    'app', 'lodash', 'js/declUtils', 'js/logger',
    'js/actionService', 'js/configurationService', 'js/viewModelProcessingFactory', 'js/appCtxService', 'js/viewModelService'
], function( app, _, declUtils, logger ) {
    'use strict';

    var exports = {};
    var _$http = null;
    var _$q = null;
    var _$rootScope = null;
    var _$templateCache = null;
    var _actionSvc = null;
    var _cfgSvc = null;
    var _vmProcFactory = null;
    var _appCtxSvc = null;
    var _viewModelService = null;

    /**
     * Return the view model and view for the given declViewAndViewModel Id.
     *
     * @param {String} declViewAndViewModelId - ID of the View and ViewModel.
     *
     * @return {promise} Object with 'view' and 'viewModel' set.
     */
    exports.getPanelContent = function( declViewAndViewModelId ) {
        return getViewAndViewModel( declViewAndViewModelId, false );
    };

    /**
     * Fetch ViewModel JSON
     *
     * @param {String} declViewModelId - id of viewModel
     *
     * @return {promise} Object with 'viewModel' set.
     */
    exports.getViewModelById = function( declViewModelId ) {
        return getViewAndViewModel( declViewModelId, true );
    };

    /**
     * Fetch View and ViewModelJson using Rest API.
     *
     * @param {String} declViewAndViewModelId - ...
     * @param {Object} viewAndViewModelRepoConfiguration - ...
     *
     * @return {Promise} Resolved when 'post' is complete.
     */
    var callRestAPI = function( declViewAndViewModelId, viewAndViewModelRepoConfiguration ) {
        viewAndViewModelRepoConfiguration.inputData.panelId = declViewAndViewModelId;

        return _$http.post( viewAndViewModelRepoConfiguration.url, viewAndViewModelRepoConfiguration.inputData );
    };

    /**
     * Fetch View and ViewModelJson using SOA .
     *
     * @param {String} declViewAndViewModelId - ...
     * @param {Object} viewAndViewModelRepoConfiguration - ...
     *
     * @return {Promise} Resolved when 'SOA Action' is complete.
     */
    var callSOA = function( declViewAndViewModelId, viewAndViewModelRepoConfiguration ) {
        /*
         * ========= Get the view and viewmodel from SOA and prepare promise =========
         */
        viewAndViewModelRepoConfiguration.inputData.panelId = declViewAndViewModelId;
        return _actionSvc.performSOAAction( viewAndViewModelRepoConfiguration );
    };

    /**
     * Fetch View and ViewModelJson using GET Url.
     *
     * @param {String} declViewId - ...
     * @param {String} declViewModelId - ...
     * @param {Object} viewAndViewModelRepoConfiguration - ...
     *
     * @return {Promise} Resolved whe operation is complete.
     */
    var callGET = function( declViewId, declViewModelId, viewAndViewModelRepoConfiguration ) {
        if( logger.isDeclarativeLogEnabled() ) {
            logger.declarativeLog( 'DECLARATIVE TRACE - Loading View/View Model ' + declViewModelId );
        }

        var viewAndViewModelUrl = {};

        if( viewAndViewModelRepoConfiguration.viewUrl.indexOf( "{{baseUrl}}" ) !== -1 ) {
            viewAndViewModelUrl = {
                view: app.getBaseUrlPath() + '/html/' + declViewModelId + 'View.html',
                viewModel: app.getBaseUrlPath() + '/viewmodel/' + declViewModelId + 'ViewModel.json'
            };
        } else {
            viewAndViewModelUrl = {
                view: viewAndViewModelRepoConfiguration.viewUrl + declViewModelId + 'View.html',
                viewModel: viewAndViewModelRepoConfiguration.viewModelUrl + declViewModelId + 'ViewModel.json'
            };
        }

        var promises = [];
        var viewAndViewModelResponse = {};

        if( declViewId ) {
            // Check if already there in template cache.
            var htmlString = _$templateCache.get( viewAndViewModelUrl.view );
            if( htmlString ) {
                viewAndViewModelResponse.view = htmlString;
            } else {
                promises.push( _$http.get( viewAndViewModelUrl.view, {
                    "cache": true
                } ).then( function( response ) {
                    _$templateCache.put( viewAndViewModelUrl.view, viewAndViewModelResponse.view );

                    viewAndViewModelResponse.view = response.data;

                    return response;
                } ) );
            }
        }

        if( declViewModelId ) {
            // Through configurationService, get the intended ViewModel, now from "a ViewModels bundle",
            // But before that get that "viewModels bundle" name from "moduleViewModelsMap", residing in assets/config
            promises.push( _cfgSvc.getCfg( 'viewmodel.' + declViewModelId ).then( function( viewModel ) {
                //Get the deep clone of the viewmodel object so that original (cached) value remains intact.
                viewAndViewModelResponse.viewModel = _.cloneDeep( viewModel );
                viewAndViewModelResponse.viewModel.skipClone = true;
                return viewAndViewModelResponse.viewModel; // ensure we're done with clone before returning
            } ) );
        }

        return _$q.all( promises ).then( function() {
            return viewAndViewModelResponse;
        } );
    };

    /**
     * Fetch the viewAndViewModelRepoConfiguration and validate it before returning it.
     *
     * @return {Service} viewAndViewModelRepoConfiguration
     */
    var getViewAndViewModelRepoConfiguration = function() {
        return app.getInjector().get( 'viewAndViewModelRepoConfiguration' );
    };

    /**
     * Fetch View and ViewModelJson using viewAndViewModelRepoConfiguration.
     *
     * @param {String} declViewAndViewModelId - ID of the View and ViewModel.
     *
     * @param {Boolean} getViewModelOnly - true if only 'viewmodel' is expected.
     *
     * @return {promise} Object with 'view'(optional) and 'viewModel' set.
     */
    var getViewAndViewModel = function( declViewAndViewModelId, getViewModelOnly ) {
        var promise = null;

        var viewAndViewModelRepoConfiguration = getViewAndViewModelRepoConfiguration();

        if( declUtils.isNil( viewAndViewModelRepoConfiguration ) ) {
            logger.error( "viewAndViewModelRepoConfiguration is missing" );

            return _$q.reject( "viewAndViewModelRepoConfiguration is missing" );
        }

        if( viewAndViewModelRepoConfiguration.actionType === "GET" ) {
            if( getViewModelOnly ) {
                promise = callGET( null, declViewAndViewModelId, viewAndViewModelRepoConfiguration );

                // Mock the configuration output Data for this case where we are loading the view and view model by direct url to the files
                viewAndViewModelRepoConfiguration = {
                    outputData: {
                        "viewModel": "viewModel"
                    }
                };
            } else {
                promise = callGET( declViewAndViewModelId, declViewAndViewModelId, viewAndViewModelRepoConfiguration );

                // Mock the configuration output Data for this case where we are loading the view and view model by direct url to the files
                viewAndViewModelRepoConfiguration = {
                    outputData: {
                        "view": "view",
                        "viewModel": "viewModel"
                    }
                };
            }
        } else if( viewAndViewModelRepoConfiguration.actionType === "RESTService" ) {
            promise = callRestAPI( declViewAndViewModelId, viewAndViewModelRepoConfiguration );

        } else if( viewAndViewModelRepoConfiguration.actionType === "TcSoaService" ) {
            promise = callSOA( declViewAndViewModelId, viewAndViewModelRepoConfiguration );
        } else {
            var declViewModel = _vmProcFactory.createDeclViewModel( {
                _viewModelId: "__panelContentSvc"
            } );
            var dataCtxNode = _$rootScope.$new();
            dataCtxNode.name = declViewAndViewModelId;
            dataCtxNode.ctx = _appCtxSvc.ctx;
            dataCtxNode.baseUrl = app.getBaseUrlPath();
            _viewModelService.setupLifeCycle( dataCtxNode, declViewModel );
            promise = declUtils.loadDependentModule( viewAndViewModelRepoConfiguration.deps, _$q, app.getInjector() ).then( function( depModuleObj ) {
                return _actionSvc.executeAction( declViewModel, viewAndViewModelRepoConfiguration, dataCtxNode, depModuleObj );
            } ).finally( function() {
                dataCtxNode.$destroy();
            } );
        }

        /*
         * ========= Process the returned promise =========
         */
        return promise.then( function( response ) {
            var viewAndViewModel = {};

            if( !declUtils.isNil( response ) ) {
                if( !getViewModelOnly ) {
                    viewAndViewModel.view = _.get( response, viewAndViewModelRepoConfiguration.outputData.view );
                }

                viewAndViewModel.viewModel = _.get( response, viewAndViewModelRepoConfiguration.outputData.viewModel );

                viewAndViewModel.viewModel._viewModelId = declViewAndViewModelId;
                viewAndViewModel.viewModel.skipClone = true;

                return viewAndViewModel;
            }

            logger.error( "Invalid response received" );

            return _$q.reject( "Invalid response received" );
        } );

    };

    /**
     * @memberof NgServices
     * @member panelContentService
     *
     * @param {$http} $http - Service to use.
     * @param {$q} $q - Service to use.
     * @param {$templateCache} $templateCache - Service to use.
     * @param {actionService} actionSvc - Service to use.
     * @param {configurationService} cfgSvc - Service to use.
     *
     * @returns {panelContentService} Instance of the service API object.
     */
    app.factory( 'panelContentService', [
        '$http', '$q', '$rootScope', '$templateCache', 'actionService', 'configurationService', 'viewModelProcessingFactory', 'appCtxService', 'viewModelService',
        function( $http, $q, $rootScope, $templateCache, actionSvc, cfgSvc, vmProcFactory, appCtxService, viewModelService ) {
            _$http = $http;
            _$q = $q;
            _$rootScope = $rootScope;
            _$templateCache = $templateCache;
            _actionSvc = actionSvc;
            _cfgSvc = cfgSvc;
            _vmProcFactory = vmProcFactory;
            _appCtxSvc = appCtxService;
            _viewModelService = viewModelService;
            return exports;
        }
    ] );
} );

