// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * @module js/typeDisplayName.service
 */
define( 'js/typeDisplayName.service',[ 'app', 'lodash', 'js/logger', 'js/configurationService', //
        'soa/kernel/clientMetaModel', 'soa/kernel/propertyPolicyService', 'config/typeProperties' ], //
    function( app, _, logger, cfgSvc ) {
        'use strict';

        //  FIXME this should be loaded async but before the sync API below that uses it is called
        var _typeProperties = cfgSvc.getCfgCached( 'typeProperties' );

        /**
         * Type Display Name service
         *
         * @memberof NgServices
         */
        app.factory( 'typeDisplayNameService', //
            [
                'soa_kernel_clientMetaModel',
                'soa_kernel_propertyPolicyService',
                function( cmm, propertyPolicySvc ) {
                    var DEFAULT_DISPLAY_PROPERTY = 'object_string';
                    var IS_MODIFIABLE = 'is_modifiable';

                    /**
                     * find or create an Property Policy object for a type
                     *
                     * @param {Object} objectPropertyPolicy
                     * @param {String} typeName type name
                     * @return {Object} property policy object for a given type
                     */
                    var locateOrCreatePolicyType = function( objectPropertyPolicy, typeName ) {
                        var type = _.find( objectPropertyPolicy.types, function( o ) {
                            return o.name === typeName;
                        } );
                        if( !type ) {
                            type = {
                                'name': typeName
                            };
                            objectPropertyPolicy.types.push( type );
                        }
                        return type;
                    };

                    /**
                     * find or create an Property Policy object for a property
                     *
                     * @param {Object} objectPolicyType Object policy type
                     * @param {String} propertyName property name
                     * @return {Object} Property Policy Object
                     *
                     */
                    var locateOrCreatePolicyProperty = function( objectPolicyType, propertyName ) {
                        if( !objectPolicyType.properties ) {
                            objectPolicyType.properties = [];
                        }
                        var policyProperty = _.find( objectPolicyType.properties, function( p ) {
                            return p.name === propertyName;
                        } );
                        if( !policyProperty ) {
                            policyProperty = {
                                'name': propertyName
                            };
                            objectPolicyType.properties.push( policyProperty );
                        }
                        return policyProperty;
                    };

                    /**
                     * @param {String} typeName type name
                     * @return {Object} type display property data
                     */
                    var getTypeInfo = function( typeName ) {
                        var typeDisplayPropertyData = _typeProperties[ typeName ];

                        if( typeDisplayPropertyData ) {
                            return typeDisplayPropertyData;
                        }

                        var type = cmm.getType( typeName );

                        if( type ) {

                            for( var i = 1; i < type.typeHierarchyArray.length; i++ ) {
                                typeDisplayPropertyData = _typeProperties[ type.typeHierarchyArray[ i ] ];

                                if( typeDisplayPropertyData ) {
                                    return typeDisplayPropertyData;
                                }
                            }
                        }
                    };

                    var exports = {};

                    /**
                     * This method will return the display property name for the given type Name
                     *
                     * @param {String} typeName
                     * @return {String} display property name
                     */
                    exports.getDisplayPropertyName = function( typeName ) {
                        var typeDisplayPropertyData = getTypeInfo( typeName );
                        if( typeDisplayPropertyData && typeDisplayPropertyData.displayProperty ) {
                            return typeDisplayPropertyData.displayProperty;
                        }
                        return DEFAULT_DISPLAY_PROPERTY;
                    };

                    /**
                     * This method will return the display name for the passed in ModelObject
                     *
                     * @param {Object} modelObject
                     * @return {String} displayName
                     */
                    exports.getDisplayName = function( modelObject ) {
                        if( modelObject ) {
                            var displayProperty = exports.getDisplayPropertyName( modelObject.type );

                            if( displayProperty && modelObject.props && modelObject.props[ displayProperty ] &&
                                modelObject.props[ displayProperty ].uiValues &&
                                modelObject.props[ displayProperty ].uiValues.length > 0 ) {
                                return modelObject.props[ displayProperty ].uiValues[ 0 ];
                            }

                            var type = modelObject.modelType;
                            if( !type ) {
                                type = cmm.getType( modelObject.uid );
                            }

                            if( type ) {
                                // Since the passed in "modelObject" was actually a ModelType, return the display value.
                                return type.displayName;
                            }

                            // Default to showing the modelObject UID.
                            return modelObject.uid;
                        }
                    };

                    // Initialize

                    /**
                     * initialize the property policy from the required aw.properties which is generated as part of the build
                     * from the typeProperties.json files.
                     */
                    var objectPropertyPolicy = {
                        types: []
                    };

                    _.forEach( _typeProperties, function( typeProperty, typeName ) {
                        if( typeProperty ) {
                            if( typeProperty.additionalProperties ) {
                                objectPropertyPolicy.types.push( {
                                    name: typeName,
                                    properties: typeProperty.additionalProperties
                                } );
                            }
                            var displayPropertyName = exports.getDisplayPropertyName( typeName );
                            var objectPolicyType = locateOrCreatePolicyType( objectPropertyPolicy, typeName );
                            locateOrCreatePolicyProperty( objectPolicyType, displayPropertyName );
                        }
                    } );

                    // WARNING: Please do not remove IPropertyName.IS_MODIFIABLE, this property is used in a lot of places.
                    // If you remove this property, then you need to visit all the places to make sure there is no regression.
                    // Reference CP PLM391889 for more information.
                    var isModifiableTypes = [ 'Awb0Element', 'Awp0XRTObjectSetRow', 'Fnd0TableRow', 'ImanRelation', 'Signoff',
                        'WorkspaceObject' ];

                    _.forEach( isModifiableTypes, function( typeName ) {
                        var objectPolicyType = locateOrCreatePolicyType( objectPropertyPolicy, typeName );
                        locateOrCreatePolicyProperty( objectPolicyType, IS_MODIFIABLE );
                    } );

                    if( !_.isEmpty( objectPropertyPolicy.types ) ) {
                        propertyPolicySvc.register( objectPropertyPolicy );

                        //Register once for the entire life of application Global Property policy no unregister needed
                        //that is why unregister is commented out but needed to pass static code analysis
                        //propertyPolicySvc.unregister( xxx );
                    }

                    return exports;
                } ] );

        /**
         * Since this module can be loaded GWT-side by the ModuleLoader class we need to return an object indicating which
         * service should be injected to provide the API for this module.
         */
        return {
            moduleServiceNameToInject: 'typeDisplayNameService'
        };
    } );

