// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global
 define
 */

/**
 * Defines {@link layoutService} which manages layouts.
 *
 * @module js/layout.service
 * @requires app
 * @requires angulare
 */
define( 'js/layout.service',[ 'app', 'angular', 'js/eventBus', 'js/localStorage', 'js/appCtxService' ],
    function( app, ngModule, eventBus ) {
        'use strict';

        /**
         * Layout service to manage comfy/compact layout
         *
         * @class layoutService
         * @memberOf NgServices
         */
        app.service( 'layoutService', [ 'appCtxService', 'configurationService', 'localStorage',
            function( appCtxService, configurationService, localStorage ) {

                var self = this;

                /**
                 * Layout context
                 */
                var layoutContext = 'layout';

                /**
                 * Initialize the layout service
                 */
                self.init = function() {
                    localStorage.subscribe( layoutContext, function( event ) {
                        self.setLayout( event.newValue );
                    } );
                    self.setInitialLayout();
                };

                /**
                 * Set the layout to the layout in local storage or the default layout
                 */
                self.setInitialLayout = function() {
                    var localLayout = self.getLocalStorageLayout();
                    if( localLayout ) {
                        self.setLayout( localLayout );
                    } else {
                        self.setLayout( self.getDefaultLayout() );
                    }
                };

                /**
                 * Get the current layout from local storage
                 *
                 * @return {String} The layout in local storage
                 */
                self.getLocalStorageLayout = function() {
                    return localStorage.get( layoutContext );
                };

                /**
                 * Get the default layout defined by the workspace
                 *
                 * @return {String} The default workspace layout
                 */
                self.getDefaultLayout = function() {
                    return 'comfy';
                };

                /**
                 * Set the current layout
                 *
                 * @example layoutService.setLayout( 'comfy' )
                 *
                 * @param newLayout {String} - The new layout
                 */
                self.setLayout = function( newLayout ) {
                    if( self.getLayout() !== newLayout ) {
                        document.body.classList.remove( self.getLayout() ) ;
                        document.body.classList.add( newLayout );
                        appCtxService.registerCtx( layoutContext, newLayout );
                        localStorage.publish( layoutContext, newLayout );
                        var rowHeight = newLayout === 'compact' ? 24 : 34;
                        eventBus.publish( 'LayoutChangeEvent', {
                            layout: newLayout,
                            rowHeight: rowHeight
                        } );
                    }
                };

                /**
                 * Get the current layout
                 *
                 * @return {String} The current layout
                 */
                self.getLayout = function() {
                    return appCtxService.getCtx( layoutContext );
                };

            } ] );

        return {
            moduleServiceNameToInject: 'layoutService'
        };
    } );

