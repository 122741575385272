// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global define requirejs */

/**
 * This module provides reusable functions related to handling AngujarJS operations.
 *
 * @module js/ngUtils
 */
define( 'js/ngUtils',[
    'app',
    'angular',
    'jquery',
    'lodash',
    'js/logger'
], function( app, ngModule, $, _, logger ) {
    'use strict';

    var exports = {};

    /**
     * This function simulates the 'ng-include' directive and allows DOM elements created outside the AngularJS system
     * to be 'compiled' and hooked up into that system.
     *
     * @param {Element} parentElement - The DOM element above the 'ctrlElement' where the parent scope can be gotten
     *            from.
     *
     * @param {Element} ctrlElement - The DOM element containing the HTML tags defining a 'controller' to be 'compiled'
     *            into the AngularJS system.
     *
     * @param {Object} appCtxSvc - The application context service. Required for processing Declarative view returned
     *            from getDeclarativeStyleSheets SOA.
     *
     * @param {DeclViewModel} declViewModel - The object to set as the 'data' property on the controller's '$scope'.
     *
     * @param {Object} scopeData - Arbitrary object to be set as the primary '$scope' (i.e. 'context') of the new
     *            AngularJS controller.
     *
     * @return {Controller} Reference to the new AngularJS controller function that was created/compiled and set onto
     *         the given 'ctrlElement'.
     */
    exports.include = function( parentElement, ctrlElement, appCtxSvc, declViewModel, scopeData ) {
        var compiledHTMLs =  exports.compile( parentElement, ctrlElement, appCtxSvc, declViewModel, scopeData );
        if ( compiledHTMLs ){
            return compiledHTMLs.controller();
        }
        return null;
    };


    /**
     * This function compiles an angularJS Template to HTML DOMElement, allows us to insert an angularJS Template into 
     * non-angularJS Widget.
     *
     * @param {Element} parentElement - The DOM element above the 'ctrlElement' where the parent scope can be gotten
     *            from.
     *
     * @param {Element} ctrlElement - The DOM element containing the HTML tags defining a 'controller' to be 'compiled'
     *            into the AngularJS system.
     *
     * @param {Object} appCtxSvc - The application context service. Required for processing Declarative view returned
     *            from getDeclarativeStyleSheets SOA.
     *
     * @param {DeclViewModel} declViewModel - The object to set as the 'data' property on the controller's '$scope'.
     *
     * @param {Object} scopeData - Arbitrary object to be set as the primary '$scope' (i.e. 'context') of the new
     *            AngularJS controller.
     *
     * @return {Object} Compile result to specific angularJS Template.
     */
    exports.compile = function( parentElement, ctrlElement, appCtxSvc, declViewModel, scopeData ) {
        try {
            var docNgElement = ngModule.element( document.body );
            var parentNgElement = ngModule.element( parentElement );
            var contrNgElement = ngModule.element( ctrlElement );

            var parentScope = parentNgElement.scope();

            /**
             * Check if the parent scope does not exists OR it is the 'root' scope.<BR>
             * If so: Create a new child scope based on the document's scope.
             * <P>
             * Note: This can occur when the parentElement is part of a DOM tree that is not yet attached into the
             * document (i.e. it is still being built). Currently, when this happens there seems to a node with the
             * class 'locationPanel' at the top of the fragment. A new child scope of the document scope will be added
             * to this 'locationPanel' and used as the parent scope for the ctrlElement.
             * <P>
             * Note: We do not want to use the 'root' scope for inserting new elements into since it hass been shown to
             * not be the one the API is eventually added to (it will be a child of it anyway).
             */
            if( !parentScope || parentScope.$id === 1 ) {
                var docScope = docNgElement.scope();
                if( docScope ) {
                    parentScope = docScope.$new();
                    $( '.locationPanel' ).data( '$scope', parentScope );
                }
            }

            if( parentScope ) {
                var ctrlScope = parentScope.$new();
                $( ctrlElement ).data( '$scope', ctrlScope );
            }

            var docInjector = app.getInjector();
            if( docInjector ) {
                var compileFn = docInjector.get( '$compile' );
                if( compileFn ) {
                    var compiledFn = compileFn( contrNgElement );

                    ctrlScope = contrNgElement.scope();

                    if( scopeData ) {
                        _.forEach( scopeData, function( propValue, propName ) {
                            ctrlScope[ propName ] = propValue;
                        } );
                    }

                    // "scope.ctx" is required to process Declarative view returned from getDeclarativeStyleSheets SOA
                    if( appCtxSvc ) {
                        ctrlScope.ctx = appCtxSvc.ctx;
                    }

                    if( declViewModel ) {
                        ctrlScope.data = declViewModel;
                    }

                    if( ctrlScope && compiledFn ) {
                        compiledFn( ctrlScope );

                        return contrNgElement;
                    }
                }
            }
        } catch ( e ) {
            logger.error( e );
        }

        return null;
    };

    /**
     * This function loads dependency.
     *
     * @param {String} jsFile - The Dependend JS File
     *
     * @param {String} depName - The AngularJs Dependency
     *
     * @param {bool} isErrorOut - If true throw exception
     *
     * @return {Promise} Reference to related module as object.
     */
    exports.load = function( jsFile, depName, isErrorOut ) {
        var $q = app.getInjector().get( '$q' );
        return $q( function( resolve, reject ) {
            requirejs( [ jsFile ], function( lib ) {
                var obj = null;
                if( depName ) {
                    try {
                        obj = app.getInjector().get( depName );
                    } catch ( e ) {
                        if( isErrorOut ) {
                            reject( e );
                        }
                        obj = null;
                    }
                } else {
                    obj = lib;
                }
                resolve( obj );
            }, function( e ) {
                if( isErrorOut ) {
                    reject( e );
                }
                resolve( null );
            } );
        } );
    };

    /**
     * This function returns angularJS Element from HTML String. A simple wrapper to angular.element
     * TODO: Can we try to not pass in 
     *
     * @param {DOMElement} htmlContent - The Dependend JS File
     * 
     * @param {String} htmlContent - The Dependend JS File
     *
     * @return {Object} AngularJS Element object.
     */
    exports.element = function( htmlContent ) {
        return ngModule.element(htmlContent);
    };

    return exports;
} );

