// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/* global define */

/**
 * This represents the Location Navigation and tracking
 *
 * @module js/locationNavigation.service
 */
define( 'js/locationNavigation.service',[
    'app', 'js/logger',
    'js/leavePlace.service'
], function( app, logger ) {
    'use strict';

    var exports = {};

    // service and module references
    var _$state = null;

    var _$urlMatcherFactory = null;

    // members
    var _stateStack = [];

    var _popState = null;

    var _$rootScope = null;

    /**
     * Function parses a URL and returns an object consisting of state and params
     */
    var parseUrl = function( url ) {

        var stateStart = url.lastIndexOf( '#' );
        var paramsStart = url.lastIndexOf( '?' );
        var state = url.substring( stateStart + 1 );
        var paramsStr = "";
        var params = {};
        if( paramsStart > -1 ) {
            state = url.substring( stateStart + 1, paramsStart );
            paramsStr = url.substr( paramsStart + 1 );
            var paramPairs = paramsStr.split( "&" );
            for( var i = 0; i < paramPairs.length; i++ ) {
                var keyValue = paramPairs[ i ].split( "=" );
                if( keyValue.length === 2 ) {
                    params[ keyValue[ 0 ] ] = _$urlMatcherFactory.type( 'string' )
                        .decode( decodeURIComponent( keyValue[ 1 ] ) );
                }
            }
        }

        if( state === '/com.siemens.splm.clientfx.tcui.xrt.showObject' ) {
            state = 'com_siemens_splm_clientfx_tcui_xrt_showObject';
        }

        return {
            state: {
                name: state
            },
            params: params,
            url: url
        };
    };

    /**
     * goBack function wired to the goBack Button
     */
    exports.goBack = function() {

        _popState = _stateStack.pop();

        if( _popState && _popState.state ) {

            logger.trace( '&&&&& go pop state' + _popState.state.name );

            _$state.go( _popState.state.name, _popState.params, {
                inherit: false
            } );

            if( _$rootScope.$$phase !== '$apply' && _$rootScope.$$phase !== '$digest' ) {
                _$rootScope.$apply();
            }

        }

    };

    exports.peekLastState = function() {
        if( _stateStack && _stateStack.length > 0 ) {
            return _stateStack[ _stateStack.length - 1 ];
        }
        return undefined;
    };

    /**
     * Invoked when the state change was successful from UI router
     */
    exports.routeStateChangeSuccess = function( event, toState, toParams, fromState, fromParams ) {

        if( fromState && fromState.name !== "checkAuthentication" ) {

            if( _popState && _popState.state.name === toState.name ) {
                _popState = null;
                return;
            }

            if( toState && toState.name && fromState && fromState.name && toState.name === fromState.name &&
                toState.name !== "com_siemens_splm_clientfx_tcui_xrt_showObject" ) {
                return;
            }

            if( toState && toState.parent && fromState && fromState.parent && toState.parent === fromState.parent ) {
                return;
            }

            var newState = {
                state: fromState,
                params: fromParams
            };

            if( _stateStack.length > 0 ) {

                var lastState = exports.peekLastState();

                if( lastState.state.name !== newState.state.name ) {
                    _stateStack.push( newState );
                    logger.trace( '^^^^1 pushing newState ' + newState.state.name );

                } else if( newState.state.name === "com_siemens_splm_clientfx_tcui_xrt_showObject" &&
                    lastState.params.uid !== newState.params.uid && newState.params.uid !== toParams.uid ) {
                    _stateStack.push( newState );
                    logger.trace( '^^^^2 pushing newState ' + newState.state.name );

                }
            } else if( !newState.state.abstract ) {
                _stateStack.push( newState );
                logger.trace( '^^^^3 pushing newState ' + newState.state.name );
            }

        }
    };

    /**
     * Function to update the state parameter
     */
    exports.updateStateParameter = function( paramName, paramValue ) {
        _$state.params[ paramName ] = paramValue;
        _$state.go( '.', _$state.params, {
            inherit: true
        } );
    };

    /**
     * Function to transition to a new state
     */
    exports.go = function( transitionTo, toParams, options ) {
        _$state.go( transitionTo, toParams, options );
    };

    /**
     * The native location Navigation service.
     *
     * @member locationNavigationService
     * @memberof NgServices
     */
    app.factory( 'locationNavigationService', [
        '$state',
        '$rootScope',
        '$urlMatcherFactory',
        function( $state, $rootScope, $urlMatcherFactory ) {

            _$state = $state;

            _$rootScope = $rootScope;

            _$urlMatcherFactory = $urlMatcherFactory;

            $rootScope.$on( '$locationChangeSuccess', function( event, newUrl, oldUrl ) {
                var newLocation = parseUrl( newUrl );
                var oldLocation = parseUrl( oldUrl );

                /*
                 * Several location changes are made during show object location as query parameters are added do not
                 * want to check changes until toLocation are valid locations
                 */
                if( newLocation.state.name === 'com_siemens_splm_clientfx_tcui_xrt_showObject' &&
                    oldLocation.state.name === 'com_siemens_splm_clientfx_tcui_xrt_showObject' && newLocation.params &&
                    newLocation.params.uid ) {

                    if( oldLocation.params ) {
                        if( newLocation.params.uid !== oldLocation.params.uid ) {

                            exports.routeStateChangeSuccess( event, newLocation.state, newLocation.params,
                                oldLocation.state, oldLocation.params );

                        }

                    } else {
                        exports.routeStateChangeSuccess( event, newLocation.state, newLocation.params,
                            oldLocation.state, oldLocation.params );
                    }

                }
                logger.trace( '#### locationChangeSuccess changed! new: ' + newUrl + ', oldUrl: ' + oldUrl );
            } );

            return exports;
        }
    ] );

    /**
     * Since this module can be loaded GWT-side by the ModuleLoader class we need to return an object indicating which
     * service should be injected to provide the API for this module.
     */
    return {
        moduleServiceNameToInject: 'locationNavigationService'
    };

} );

