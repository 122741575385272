// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * native implementation for TcSessionData. This tracks some Teamcenter specific version information and user session
 * related apis.
 *
 * @module js/TcSessionData
 */
define( 'js/TcSessionData',[ //
    'app', //
    'lodash', //
    'js/localStorage', //
    'js/analyticsService', //
    'js/browserUtils', //
    'js/hosting/hostConst_ConfigKeys', //
    //
    'js/TcServerVersion', //
    'js/TcAWServerVersion', //
    'js/typeDisplayName.service', //
    'js/appCtxService', //
    'js/configurationService', //
    'js/sessionContext.service', //
    'js/theme.service' //
], function( app, _, localStrg, analyticsSvc, browserUtils, hostConfigKeys ) {
    'use strict';

    var _displayCurrentCountry = false;
    var _hasProjects = false;
    var _tcServerVersion;
    var _awServerVersion;
    var _appCtxSvc;
    var _cfgSvc;
    var _sessionCtxSvc;
    var _themeSvc;

    var WEB_XML_SOA_PROXY_CONTEXT = "tc/";
    var _postLoginStages = [];
    var exports = {};

    /**
     * Given a complete path and file name, return just the file name
     * @param {*} longFilename complete absolute path
     */
    function getFilename(longFilename) {
        var logFilename = longFilename;
        var lastSlash = logFilename.lastIndexOf("\\");
        if (lastSlash !== -1) {
            logFilename = logFilename.substring(lastSlash + 1);
        }
        return logFilename;
    }

    /**
     * given the SOA response for GetTCSessionInfo3, pull out the version strings and other information.
     *
     * @param {String} soaResponse - soa payload from GetTCSessionInfo with the version information and other
     *            parameters.
     */
    exports.parseSessionInfo = function( soaResponse ) {

        // expecting the literal from string to be exact case match
        if( soaResponse.extraInfoOut && soaResponse.extraInfoOut.hasProjects ) {
            _hasProjects = soaResponse.extraInfoOut.hasProjects === 'true';
        } else {
            _hasProjects = false;
        }

        if( soaResponse.extraInfoOut && soaResponse.extraInfoOut.displayCurrentCountryPage ) {
            _displayCurrentCountry = soaResponse.extraInfoOut.displayCurrentCountryPage === 'true';
        } else {
            _displayCurrentCountry = false;
        }

        _appCtxSvc.registerCtx( 'hasProjects', _hasProjects );
        _postLoginStages = soaResponse.extraInfoOut.AWC_PostLoginStages;

        if( _postLoginStages && _postLoginStages.length > 0 ) {
            exports.setpostLoginStageList( _postLoginStages );
        }

        var svrVer = soaResponse.extraInfoOut.TCServerVersion;
        _tcServerVersion.parseVersionInfo( svrVer );
        var awVer = soaResponse.extraInfoOut.AWServerVersion;
        _awServerVersion.parseVersionInfo( awVer );

        if( soaResponse.analyticsData && soaResponse.analyticsData.isDataCollectionEnabled ) {
            var solution;
            analyticsSvc.enable( soaResponse.analyticsData.useInternalServer ).then( function() {
                return _cfgSvc.getCfg( 'solutionDef' );
            } ).then( function( solutionDef ) {
                solution = solutionDef;
                return _cfgSvc.getCfg( 'versionConstants' );
            } ).then( function( versionConstants ) {
                // Add the analytics properties from the SOA Response to property object
                // and Teamcenter Server version, Active workspace server version, theme
                var property = _.assign( {}, soaResponse.analyticsData.analyticsExtraInfo );
                property['clientVersion'] = versionConstants.version;
                property['serverVersion']  = _awServerVersion.baseLine;
                property['Theme In Use'] = _themeSvc.getTheme();

                property['AW Client Width'] = parseInt(window.innerWidth);
                property['AW Client Height'] = parseInt(window.innerHeight);
                property['Browser Zoom Level'] = Math.round(window.devicePixelRatio * 100);

                property['Participating']  = 'true';

                // Add the Host application details
                var hostType = 'Standalone';
                if( _appCtxSvc  && _appCtxSvc.ctx.aw_host_type ) {
                    hostType = _appCtxSvc.ctx.aw_host_type;
                }
                property['Host Application'] = hostType;

                if( _appCtxSvc.ctx.aw_hosting_config ){
                    var hostVersion = _appCtxSvc.ctx.aw_hosting_config[hostConfigKeys.HOST_VERSION_INFO];
                    if( hostVersion ){
                        property['Host Version'] = hostVersion;
                    }
                }

                // Make sure that we are not reporting Vendor Id
                if( property['Vendor'] ) {
                    delete property['Vendor'];
                }

                analyticsSvc.logEvent( solution.solutionName, property );
            } );
        } else if( soaResponse.analyticsData && !soaResponse.analyticsData.isDataCollectionEnabled ) {
            // Disable the Analytics reporting, if it is disabled in the tcserver.
            if( soaResponse.analyticsData.analyticsExtraInfo ) {
                // Replace Vendor Id with Site Id for reporting the opt-out.
                var encodedSiteIdInfo = '';
                if ( soaResponse.analyticsData.analyticsExtraInfo.Site ){
                    encodedSiteIdInfo = soaResponse.analyticsData.analyticsExtraInfo.Site;
                }

                analyticsSvc.disable( soaResponse.analyticsData.useInternalServer, encodedSiteIdInfo );
            }
        }

        var tcSessionData = {};
        tcSessionData.tcMinorVersion = exports.getTCMinorVersion();
        tcSessionData.tcMajorVersion = exports.getTCMajorVersion();
        tcSessionData.tcQRMNumber = exports.getTCQRMNumber();
        tcSessionData.serverVersion = exports.toString();
        tcSessionData.protocol = exports.getProtocol();
        tcSessionData.server = browserUtils.getBaseURL() + WEB_XML_SOA_PROXY_CONTEXT;

        if (soaResponse.extraInfoOut.LogFile) {
            tcSessionData.logFile = getFilename(soaResponse.extraInfoOut.LogFile);
        }

        var userAgentInfo = {};
        userAgentInfo.userApplication = _sessionCtxSvc.getClientID();
        userAgentInfo.userAppVersion = _sessionCtxSvc.getClientVersion();

        _appCtxSvc.registerCtx( "tcSessionData", tcSessionData );
        _appCtxSvc.registerCtx( "userAgentInfo", userAgentInfo );
    };


    /**
     * prop getter for the hasProjects boolean value
     *
     * @return {Boolean} value for whether or not there is any projects data.
     */
    exports.hasProjects = function() {
        return _hasProjects;
    };

    /**
     * prop getter for the displayCurrentCountry boolean value
     *
     * @return {Boolean} display country/geography
     */
    exports.displayCurrentCountry = function() {
        return _displayCurrentCountry;
    };

    exports.getpostLoginStages = function() {
        return _postLoginStages;
    };

    exports.setpostLoginStageList = function( postLoginStage ) {

        var splitArray = postLoginStage.split( "," );
        var postLoginStageKey = "postLoginStagesKey";
        var postLoginStages = localStrg.get( postLoginStageKey );

        if( !postLoginStages ) {
            postLoginStages = [];
            for( var i = 0; i < splitArray.length; i++ ) {
                var stage = {};
                stage.name = splitArray[ i ];
                stage.status = false;
                stage.priority = i;
                postLoginStages.push( stage );
            }
            localStrg.publish( postLoginStageKey, JSON.stringify( postLoginStages ) );
        }
    };

    /**
     * property getter for the Tc version string, Major number.
     *
     * defer to the nested version construct.
     *
     * @returns {int} Major version information
     */
    exports.getTCMajorVersion = function() {
        if( _tcServerVersion && _tcServerVersion.majorVersion ) {
            return _tcServerVersion.majorVersion;
        }
        return 0;
    };

    /**
     * property getter for the Tc version string, Minor number.
     *
     * defer to the nested version construct.
     *
     * @returns {int} Minor version information
     */
    exports.getTCMinorVersion = function() {
        if( _tcServerVersion && _tcServerVersion.minorVersion ) {
            return _tcServerVersion.minorVersion;
        }
        return 0;
    };

    /**
     * property getter for the Tc version string, QRM number.
     *
     * defer to the nested version construct.
     *
     * @returns {int} QRM bumber
     */
    exports.getTCQRMNumber = function() {
        if( _tcServerVersion && _tcServerVersion.qrmNumber ) {
            return _tcServerVersion.qrmNumber;
        }
        return 0;
    };

    /**
     * some odd logic - uses the version info to determine signed in state??
     *
     * TODO - migrate this to something more specific or remove? SessionManagerService should be the authority.
     *
     * @return {Boolean} true if a server version exists, false otherwise.
     */
    exports.isSignedIn = function() {
        return Boolean( _tcServerVersion );
    };

    /**
     * generate a version display string
     *
     * @return {String} formatted version string
     */
    exports.toString = function() {
        return "Server Build " + _awServerVersion.toString() + "\nServer Version: " + _tcServerVersion.toString();
    };

    /**
     * prop getter for the getProtocol string value
     *
     * @return {String} value for protocol.
     */
    exports.getProtocol = function() {
        var baseUrl = browserUtils.getBaseURL();
        if( baseUrl !== null && baseUrl !== "" ) {
            return baseUrl.substring( 0, baseUrl.indexOf( "://", 0 ) );
        }
        return null;
    };

    /**
     * Service definition for the TcSessionData service.
     *
     * @member TcSessionData
     * @memberof NgServices
     *
     * @param {TcServerVersion} tcSvrVer - Service to use.
     * @param {TcAWServerVersion} awSvrVer - Service to use.
     * @param {typeDisplayNameService} typeDisplayNameSvc - Service to use.
     * @param {appCtxService} appCtxSvc - Service to use.
     * @param {configurationService} cfgSvc - Service to use.
     * @param {SessionContextService} sessionCtxSvc - Service to use.
     * @param {themeService} themeSvc - Service to use.
     *
     * @return {TcSessionData} service exports
     */
    app.factory( 'TcSessionData', [ //
        'TcServerVersion', 'TcAWServerVersion', 'typeDisplayNameService', 'appCtxService',
        'configurationService', 'SessionContextService', 'themeService',
        function( tcSvrVer, awSvrVer, typeDisplayNameSvc, appCtxSvc, cfgSvc, sessionCtxSvc, themeSvc ) {
            _tcServerVersion = tcSvrVer;
            _awServerVersion = awSvrVer;
            _appCtxSvc = appCtxSvc;
            _cfgSvc = cfgSvc;
            _sessionCtxSvc = sessionCtxSvc;
            _themeSvc = themeSvc;

            return exports;
        }
    ] );

    /**
     * Since this module can be loaded GWT-side by the ModuleLoader class we need to return an object indicating which
     * service should be injected to provide the API for this module.
     */
    return {
        moduleServiceNameToInject: 'TcSessionData'
    };



} );

