// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global
 define
 */

/**
 * List populated by ng-repeat from controller's lov data and progressively loaded via the data services.
 *
 * @module js/aw-checkbox-list.directive
 */
define( 'js/aw-checkbox-list.directive',[ 'app', 'js/aw.checkbox.list.controller', 'js/aw-property-image.directive', 'js/aw-when-scrolled.directive' ],    function( app ) {
        'use strict';

        /**
         * List populated by ng-repeat from controller's lov data and progressively loaded via the data services.
         *
         * @example <aw-checkbox-list prop="prop"/>
         *
         * @member aw-checkbox-list
         * @memberof NgElementDirectives
         */
        app.directive( "awCheckboxList", function() {
            return {
                restrict: "E",
                templateUrl: app.getBaseUrlPath() + '/html/aw-checkbox-list.directive.html',
                controller: 'awCheckboxListController',
                link: function( $scope ) {

                    // generate place-holder array for selected values not yet loaded
                    $scope.selectedPHolders = [];
                    var vmProp = $scope.$parent.prop;

                    for( var inx = 0; inx < vmProp.dbValues.length; inx++ ) {
                        $scope.selectedPHolders.push( JSON.parse( '{ "propInternalValue": "' + vmProp.dbValues[ inx ] + '", "propDisplayValue": "' + vmProp.uiValues[ inx ] + '", "sel": true }' ) );
                    }
                }
            };
        } );
    } );

