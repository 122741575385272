// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global
 define
 */

/**
 * Defines {@link NgControllers.awPropertyArrayValController}
 *
 * @module js/aw.property.array.val.controller
 */
define( 'js/aw.property.array.val.controller',[ 'app', 'jquery', 'lodash', 'js/eventBus', //
'js/uwPropertyService', 'js/uwSupportService', 'js/localeService' ], //
function( app, $, _, eventBus ) {
    'use strict';

    /**
     * Controller for {@link NgElementDirectives.aw-property-array-val} directive.
     *
     * @member awPropertyArrayValController
     * @memberof NgControllers
     */
    app.controller( 'awPropertyArrayValController', //
    [
        '$scope',
        '$element',
        'uwPropertyService',
        'uwSupportService',
        'localeService', //
        function( $scope, $element, uwPropertySvc, uwSupportSvc, localeSvc ) {
            if( !$scope.prop ) {
                return;
            }

            var self = this;

            /**
             * Clear edit widget after adding the value to the array.
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {String} type - Data type
             * @param {String} hint - rendering hint
             */
            self.clearWidget = function( type, hint ) {
                switch( type ) {
                case "STRINGARRAY":
                case "INTEGERARRAY":
                case "DOUBLEARRAY":
                case "OBJECTARRAY":
                    $scope.dummyProp.dbValue = null;
                    $scope.dummyProp.uiValue = null;
                    break;
                case "DATEARRAY":
                    $scope.dummyProp.dbValue = null;
                    $scope.dummyProp.uiValue = null;
                    $scope.dummyProp.dateApi.dateValue = null;
                    $scope.dummyProp.dateApi.timeValue = null;
                    break;
                case "BOOLEANARRAY":
                    switch( hint ) {
                    case "radiobutton":
                        $scope.dummyProp.dbValue = null;
                        $scope.dummyProp.uiValue = null;
                        break;
                    default:
                        break;
                    }
                    break;
                default:
                    break;
                }
            };

            /**
             * To set the array text place holder
             *
             * @param {String} bundleKey - property's bundleKey
             */
            var setArrayTextPlaceHolder = function( bundleKey ) {
                localeSvc.getTextPromise().then( function( localTextBundle ) {
                    $scope.dummyProp.propertyRequiredText = localTextBundle[ bundleKey ];
                } );
            };

            /**
             * Change required flag of editable widget which is data bound to dummy prop based on the values added to
             * array property. FALSE if property's dbValue is not empty
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {String} isRequired - property's isRequired flag
             * @param {String} propDbValue - dbValue of property to check if values exist for a required property
             */
            self.changeDummyPropRequired = function( isRequired, propDbValue ) {
                if( !isRequired ) {
                    return;
                }

                if( propDbValue && propDbValue.length > 0 ) {
                    $scope.dummyProp.isRequired = false;
                    $scope.dummyProp.propertyRequiredText = "";
                } else {
                    $scope.dummyProp.isRequired = isRequired;
                    setArrayTextPlaceHolder( "REQUIRED_TEXT" );
                }
            };

            /**
             * Check max array length and disable the ability to add values to array once maximum value is reached and
             * also modify place holder text
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {String} maxArrayLength - property's max array length
             * @param {String} propDbValue - dbValue of property to check if values exist for a required property
             * @param {String} isEnabled - property's isEnabled flag
             * @param {String} placeHolderText - place holder text of array widget
             */
            self.checkMaxArrayLength = function( maxArrayLength, propDbValue, isEnabled, placeHolderText ) {
                if( maxArrayLength === null || maxArrayLength === undefined || maxArrayLength === -1 ) {
                    return;
                }

                if( propDbValue && propDbValue.length >= maxArrayLength ) {
                    $scope.dummyProp.isEnabled = false;
                    localeSvc.getTextPromise().then( function( localTextBundle ) {
                        var maxArrayMsg = localTextBundle.MAX_ARRAY_LENGTH;
                        maxArrayMsg = maxArrayMsg.replace( '{0}', maxArrayLength );

                        $scope.dummyProp.propertyRequiredText = maxArrayMsg;
                    } );
                } else {
                    $scope.dummyProp.isEnabled = isEnabled;
                    $scope.dummyProp.propertyRequiredText = placeHolderText;
                }
            };

            var vmProp = $scope.prop;

            vmProp.editArrayInlineMode = false;
            vmProp.dirty = false;

            //Use .extend to copy vmProp into dummyProp instead, as .clone was not copying all properties like lovApi
            $scope.dummyProp = $.extend( true, {}, vmProp );
            $scope.dummyProp.overlayType = 'dummyArrayProp';

            //PR9041268 - If Array Property is Marked "Copy as Original" then Revise and SaveAs Panel still shows as Required property
            self.changeDummyPropRequired( vmProp.isRequired, vmProp.dbValue );
            if( $scope.prop && $scope.prop.displayValsModel && $scope.prop.displayValsModel.length > 0 ) {
                setArrayTextPlaceHolder( "ARRAY_PLACEHOLDER_TEXT" );
            }

            self.clearWidget( vmProp.type, vmProp.renderingHint );

            /**
             * Check to see if the dbValue is a valid value to add it to array.
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {ViewModelProperty} viewModelProperty - ViewModelProperty object that will be updated.
             * @return {Boolean} True if db value is valid
             */
            self.isValidArrayValue = function( viewModelProperty ) {
                var isValid = false;

                if( viewModelProperty && viewModelProperty.dbValue !== "" && viewModelProperty.dbValue !== null &&
                    viewModelProperty.dbValue !== undefined ) {
                    if( viewModelProperty.type === 'INTEGERARRAY' || viewModelProperty.type === 'DOUBLEARRAY' ||
                        viewModelProperty.type === 'DATEARRAY' ) {
                        if( isFinite( viewModelProperty.dbValue ) ) {
                            viewModelProperty.dbValue = Number( viewModelProperty.dbValue );
                            isValid = true;
                        }
                    } else if( viewModelProperty.type === 'BOOLEANARRAY' ) {
                        isValid = _.isBoolean( viewModelProperty.dbValue );
                    } else {
                        isValid = true;
                    }
                }

                return isValid;
            };

            /**
             * Check if valid to add based on arrayLength
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {ViewModelProperty} viewModelProperty - ViewModelProperty object
             * @return {Boolean} TRUE if arrayLength not defined OR if defined number of values should be less than or
             *         equal to array length, FALSE otherwise
             */
            self.validToAdd = function( viewModelProperty ) {
                if( viewModelProperty ) {
                    if( viewModelProperty.arrayLength === null || viewModelProperty.arrayLength === undefined ||
                        viewModelProperty.arrayLength === -1 ) {
                        return true;
                    }

                    if( viewModelProperty.dbValue.length < viewModelProperty.arrayLength ) {
                        return true;
                    }
                }

                return false;
            };

            /**
             * Trigger "dirty" via $setDirty() so that it sets $dirty flag in angular form validation
             */
            self.setFormDirty = function() {
                var inputElem = $element.find( 'input' );
                if( inputElem && inputElem.length === 0 ) {
                    inputElem = $element.find( 'textarea' );
                }

                if( inputElem && inputElem.length > 0 ) {
                    var ngModelCtrl = $( inputElem[0] ).controller( 'ngModel' );
                    if( ngModelCtrl ) {
                        ngModelCtrl.$setDirty();
                    }
                }
            };

            /**
             * Adds value to array widget, clears the edit widget after adding and marks the widget as dirty.
             *
             * @memberof NgControllers.awPropertyArrayValController
             */
            $scope.dummyProp.updateArray = function() {
                // propagate error from dummy prop to vmProp
                vmProp.error = $scope.dummyProp.error;

                if( self.isValidArrayValue( $scope.dummyProp ) && !vmProp.error && self.validToAdd( vmProp ) ) {
                    //If dummyProp.dbValue is an array e.g. in case of addObjectReference when multiple objects are selected,
                    //every object individually should be pushed into VmProp.dbValue rather than as an array.
                    if( _.isArray( $scope.dummyProp.dbValue ) ) {
                        _.forEach( $scope.dummyProp.dbValue, function( value ) {
                            vmProp.dbValue.push( value );
                        } );
                    } else {
                        vmProp.dbValue.push( $scope.dummyProp.dbValue );
                    }

                    // For LOVs update display values with LOV entry's uiValue
                    if( vmProp.hasLov ) {
                        vmProp.uiValue = $scope.dummyProp.uiValue;
                        vmProp.displayValues.push( vmProp.uiValue );

                        if( vmProp.isArray ) {
                            vmProp.displayValsModel = vmProp.displayValsModel || [];
                            vmProp.displayValsModel.push( {
                                displayValue: vmProp.uiValue,
                                selected: false
                            } );
                        }
                    }

                    uwPropertySvc.updateViewModelProperty( vmProp );

                    self.changeDummyPropRequired( vmProp.isRequired, vmProp.dbValue );
                    self.clearWidget( vmProp.type, vmProp.renderingHint );

                    self.checkMaxArrayLength( vmProp.arrayLength, vmProp.dbValue, vmProp.isEnabled,
                        vmProp.propertyRequiredText );

                    vmProp.dirty = true;
                }
            };

            /**
             * Selection of the array list is handled. First click on the cell selects the list item and next click on
             * the cell will embed edit widget into the cell so that user should be able to replace the value.
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {DOMEvent} $event - Event object
             * @param {Number} $index - selected index of the list.
             */
            $scope.selectAndEdit = function( $event, $index ) {
                var jqParentElement = $event.currentTarget;
                if( !vmProp.editArrayInlineMode ) {
                    // if the array element is already selected, enter edit mode; unless it's an objectarray
                    // also, inline lov editing in arrays regressed at some pt. disabling for now and checking
                    // for a defect...
                    if( vmProp.displayValsModel[$index].selected && vmProp.type !== 'OBJECTARRAY' && !vmProp.hasLov ) {
                        vmProp.autofocus = true;

                        vmProp.currArrayDbValue = vmProp.dbValue.slice( 0 );
                        vmProp.dbValue = vmProp.currArrayDbValue[$index];
                        vmProp.uiValue = vmProp.displayValues[$index];
                        vmProp.$index = $index;

                        uwSupportSvc.includeArrayPropertyValue( jqParentElement, jqParentElement, vmProp );

                        vmProp.editArrayInlineMode = true;
                    } else {
                        for( var i = 0; i < vmProp.displayValsModel.length; i++ ) {
                            if( i === $index ) {
                                vmProp.displayValsModel[i].selected = true;
                                $scope.lastSelected = vmProp.displayValsModel[$index];
                            } else {
                                vmProp.displayValsModel[i].selected = false;
                            }
                        }
                    }
                }
                $event.stopPropagation();
            };

            /**
             * Replaces value of array cell item and inserts non-edit widget with updated value.
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {DOMEvent} $event - Event object
             * @param {Boolean} skipUpdate - TRUE if there is no change in value then don't call
             *            'updateViewModelProperty'.
             */
            $scope.prop.updateArray = function( $event, skipUpdate ) {
                if( self.isValidArrayValue( vmProp ) && ( !vmProp.error || vmProp.hasServerValidationError ) ) {
                    if( vmProp.currArrayDbValue ) {
                        vmProp.currArrayDbValue.splice( vmProp.$index, 1, vmProp.dbValue );
                    }

                    vmProp.dirty = true;
                    vmProp.dbValue = vmProp.currArrayDbValue.slice( 0 );

                    // For LOVs update display values with LOV entry's uiValue
                    if( vmProp.hasLov ) {
                        vmProp.displayValues.splice( vmProp.$index, 1, vmProp.uiValue );

                        if( vmProp.isArray ) {
                            vmProp.displayValsModel = vmProp.displayValsModel || [];
                            vmProp.displayValsModel.splice( vmProp.$index, 1, {
                                displayValue: vmProp.uiValue,
                                selected: false
                            } );
                        }
                    }

                    if( !skipUpdate ) {
                        uwPropertySvc.updateViewModelProperty( vmProp );
                    }
                } else if( vmProp.dbValue === "" || vmProp.dbValue === null || vmProp.dbValue === undefined ) {
                    /**
                     * Empty values are not allowed while replacing existing value in array. Revert it to previous value
                     * if empty value is entered by user.
                     */
                    vmProp.dbValue = vmProp.currArrayDbValue.slice( 0 );
                }

                vmProp.editArrayInlineMode = false;
                self.insertNonEdit( $event );
            };

            /**
             * Inserts non-edit widget with updated value and destroys any previous scopes.
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {DOMEvent} $event - Event object
             */
            self.insertNonEdit = function( $event ) {
                if( $event ) {
                    vmProp.autofocus = false;
                    var inputElement = $event;

                    if( $event.currentTarget ) {
                        inputElement = $event.currentTarget;
                    }

                    var jqParentElement = $( inputElement ).closest( '.aw-state-selected .aw-jswidgets-arrayValue' );

                    var previousScopeElement = $( jqParentElement ).find( '.aw-jswidgets-property' );

                    // destroying scope of previous element
                    if( previousScopeElement ) {
                        var contrScope = previousScopeElement.scope();
                        if( contrScope ) {
                            contrScope.$destroy();
                            previousScopeElement.remove();
                        }
                    }

                    uwSupportSvc.insertNgProp( jqParentElement,
                        '<div ng-click="selectAndEdit($event, $index)">{{displayNode.displayValue}}</div>', vmProp );
                }
            };

            /**
             * Used to move UP value in the array cell list based on the index.
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {Number} $index - index of the cell list item which needs to be moved.
             */
            $scope.moveUp = function( $index ) {
                if( $index > 0 ) {
                    var currDbVal = vmProp.dbValue[$index];
                    var currDisplayVal = vmProp.displayValues[$index];
                    var currDisplayValModel = vmProp.displayValsModel[$index];

                    vmProp.dbValue.splice( $index, 1 );
                    vmProp.dbValue.splice( $index - 1, 0, currDbVal );

                    vmProp.displayValues.splice( $index, 1 );
                    vmProp.displayValues.splice( $index - 1, 0, currDisplayVal );

                    vmProp.displayValsModel.splice( $index, 1 );
                    vmProp.displayValsModel.splice( $index - 1, 0, currDisplayValModel );

                    vmProp.dirty = true;
                    self.setFormDirty();
                    uwPropertySvc.updateViewModelProperty( vmProp );
                }
            };

            /**
             * Used to move DOWN value in the array cell list based on the index.
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {Number} $index - index of the cell list item which needs to be moved.
             */
            $scope.moveDown = function( $index ) {
                if( $index <= vmProp.displayValsModel.length - 1 ) {
                    var currDbVal = vmProp.dbValue[$index];
                    var currDisplayVal = vmProp.displayValues[$index];
                    var currDisplayValModel = vmProp.displayValsModel[$index];

                    vmProp.dbValue.splice( $index, 1 );
                    vmProp.dbValue.splice( $index + 1, 0, currDbVal );

                    vmProp.displayValues.splice( $index, 1 );
                    vmProp.displayValues.splice( $index + 1, 0, currDisplayVal );

                    vmProp.displayValsModel.splice( $index, 1 );
                    vmProp.displayValsModel.splice( $index + 1, 0, currDisplayValModel );

                    vmProp.dirty = true;
                    self.setFormDirty();
                    uwPropertySvc.updateViewModelProperty( vmProp );
                }
            };

            /**
             * Removes the item from array cell list.
             *
             * @memberof NgControllers.awPropertyArrayValController
             *
             * @param {Number} $index - index of the cell list item which needs to be moved.
             */
            $scope.remove = function( $index ) {
                var removedVal = vmProp.dbValue[$index];
                vmProp.dbValue.splice( $index, 1 );

                if( vmProp.hasLov ) {
                    vmProp.displayValues.splice( $index, 1 );

                    if( vmProp.isArray ) {
                        vmProp.displayValsModel = vmProp.displayValsModel || [];
                        vmProp.displayValsModel.splice( $index, 1 );
                        //PR9041268 - Required Array property is removed from Widget then to add a place holder as Required
                        if( vmProp.displayValsModel && vmProp.displayValsModel.length === 0 ) {
                            if( vmProp.isRequired ) {
                                setArrayTextPlaceHolder( "REQUIRED_TEXT" );
                            } else {
                                setArrayTextPlaceHolder( "ARRAY_PLACEHOLDER_TEXT" );
                            }
                        }
                    }
                }

                vmProp.dirty = true;
                self.setFormDirty();

                uwPropertySvc.updateViewModelProperty( vmProp );

                self.changeDummyPropRequired( vmProp.isRequired, vmProp.dbValue );
                self.checkMaxArrayLength( vmProp.arrayLength, vmProp.dbValue, vmProp.isEnabled,
                    vmProp.propertyRequiredText );
                if( vmProp.hasLov && vmProp.isArray ) {
                    /**
                     * Fire array value removed event so that it can trigger 'validateLOVValueSelections' SOA call
                     */
                    eventBus.publish( vmProp.propertyName + ".arrayValueRemoved", {
                        index: $index,
                        dbValue: removedVal
                    } );
                }
            };
        } ] );
} );

