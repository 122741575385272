// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/*global
 define
 */

/**
 * Attribute Directive to change the visibility of an element based on a condition.
 * 
 * @module js/visible-when.directive
 */
define( 'js/visible-when.directive',[ 'app' ], //
function( app ) {
    'use strict';

    /**
     * Attribute Directive to change the visibility of an element based on a condition.
     * 
     * @example TODO
     * 
     * @member visible-when
     * @memberof NgAttributeDirectives
     */
    app.directive( 'visibleWhen', //
    [ function() {
        return {
            restrict: 'A',
            replace: true,
            link: function( scope, element, attr ) {
                scope.$watch( attr.visibleWhen, function( value ) {
                    if( value ) {
                        element.removeClass( 'hidden' );
                    } else {
                        element.addClass( 'hidden' );
                    }
                } );
            }
        };
    } ] );
} );
